.inter-light {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-medium {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-semi {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-extra {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

:root {
  --rpx: calc(100vw / 1440);
  // --vw: calc(100vw / 100);
}

@media (max-width: 649px) {
  :root {
    --rpx: calc(100vw / 430);
  }
  .pc-only {
    display: none!important;
  }
}

@media (min-width: 650px) {
  .mo-only {
    display: none!important;
  }
}


body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  line-height: 1.2;
  font-weight: 400;
  background: rgb(247, 241, 241);
}

* {
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.abs-center-x {
  left: 50%;
  transform: translateX(-50%);
}

.abs-center-y {
  top: 50%;
  transform: translateY(-50%);
}

.abs-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#app {
  width: calc(var(--vw) * 100);
  padding-top: calc(var(--rpx) * 130);
  min-height: 110vh;
  .anchor {
    left: 0;
    top: calc(var(--rpx) * (-130));
  }
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(var(--vw) * 100);
    z-index: 49;
    .nav-top {
      height: calc(var(--rpx) * 21);
      background: rgb(168, 209, 202);
    }
    .nav-content {
      height: calc(var(--rpx) * 109);
      background: rgb(247, 241, 241);
      padding-left: calc(var(--rpx) * 60);
      padding-right: calc(var(--rpx) * 60);
      .nav-inner {
        border-bottom: 1px solid rgb(31, 47, 38);
        &.no-border {
          border-bottom: 1px solid transparent;
        }
        .nav-logo {
          width: calc(var(--rpx) * 171);
          height: calc(var(--rpx) * 86);
          .bg-img {
            object-fit: contain;
          }
        }
        .nav-link {
          color: rgb(31, 47, 38);
          font-size: calc(var(--rpx) * 20);
          margin-left: calc(var(--rpx) * 30);
          &.active {
            font-weight: 600;
          }
        }
      }
    }
    #anchor-home {
      cursor: pointer;
    }
  }
  #terms-popup-open, #privacy-popup-open {
    cursor: pointer;
  }
  .home {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(var(--vw) * 100);
    .home-top {
      width: calc(var(--rpx) * 1320);
      margin-left: auto;
      margin-right: auto;
      border-bottom: 1px solid rgb(247, 241, 241);
      padding-bottom: calc(var(--rpx) * 13);
      padding-top: calc(var(--rpx) * 14);
      .home-logo {
        width: calc(var(--rpx) * 204);
        height: calc(var(--rpx) * 102);
      }
    }
    .home-text-1 {
      color: rgb(247, 241, 241);
      font-weight: 600;
      font-size: calc(var(--rpx) * 40);
      left: calc(var(--rpx) * 640);
      bottom: calc(var(--rpx) * 307);
    }
    .home-text-2 {
      color: rgb(247, 241, 241);
      font-weight: 800;
      font-size: calc(var(--rpx) * 80);
      line-height: calc(var(--rpx) * 100);
      left: calc(var(--rpx) * 60);
      bottom: calc(var(--rpx) * 112);
    }
  }
  @media (min-width: 1441px) {
    .home {
      .home-top {
        padding-top: 14px;
        padding-bottom: 13px;
        .home-logo {
          width: 204px;
          height: 102px;
        }
      }
      .home-text-1 {
        font-size: 40px;
        left: 640px;
        bottom: 307px;
      }
      .home-text-2 {
        font-size: 80px;
        line-height: 100px;
        left: 60px;
        bottom: 112px;
      }
    }
  }
  .about {
    .about-top {
      height: calc(var(--rpx) * 1024);
      .about-text {
        right: calc(var(--rpx) * 32);
        bottom: calc(var(--rpx) * 104);
        width: calc(var(--rpx) * 773);
        background: rgba(247, 241, 241, 0.7);
        border: 2px solid rgb(247, 241, 241);
        padding-top: calc(var(--rpx) * 60);
        padding-left: calc(var(--rpx) * 60);
        padding-right: calc(var(--rpx) * 60);
        padding-bottom: calc(var(--rpx) * 50);
        .text-2 {
          margin-top: calc(var(--rpx) * 60);
        }
        .about-title {
          font-size: calc(var(--rpx) * 30);
          color: rgb(31, 47, 38);
          margin-bottom: calc(var(--rpx) * 15);
          line-height: 1;
        }
        .about-desc {
          font-size: calc(var(--rpx) * 20);
          line-height: calc(var(--rpx) * 23);
          color: rgb(31, 47, 38);
        }
      }
    }
    .about-bottom {
      padding-top: calc(var(--rpx) * 128);
      padding-bottom: calc(var(--rpx) * 189);
      .about-card {
        width: calc(var(--rpx) * 424);
        height: calc(var(--rpx) * 359);
        box-shadow: calc(var(--rpx) * 4) calc(var(--rpx) * 4) calc(var(--rpx) * 10) calc(var(--rpx) * 3) rgba(31, 47, 38, 0.1);
        padding-bottom: calc(var(--rpx) * 43);
        margin-left: calc(var(--rpx) * 6);
        margin-right: calc(var(--rpx) * 6);
        .card-title {
          text-align: center;
          font-weight: 600;
          font-size: calc(var(--rpx) * 30);
          line-height: 1;
          margin-bottom: calc(var(--rpx) * 14);
          color: rgb(31, 47, 38);
        }
        .card-desc {
          text-align: center;
          font-weight: 600;
          font-size: calc(var(--rpx) * 16);
          line-height: calc(var(--rpx) * 20);
          color: rgb(152, 188, 172);
        }
        .about-img-1 {
          width: calc(var(--rpx) * 172);
          height: calc(var(--rpx) * 126.6);
          top: calc(var(--rpx) * 77.85);
        }
        .about-img-2 {
          width: calc(var(--rpx) * 151);
          height: calc(var(--rpx) * 121.4);
          top: calc(var(--rpx) * 69);
        }
        .about-img-3 {
          width: calc(var(--rpx) * 185);
          height: calc(var(--rpx) * 185);
          top: calc(var(--rpx) * 32);
        }
      }
    }
  }
  .service {
    .service-top {
      height: calc(var(--rpx) * 864);
      .service-card {
        background: rgba(247, 241, 241, 0.7);
        padding-top: calc(var(--rpx) * 60);
        padding-left: calc(var(--rpx) * 60);
        padding-right: calc(var(--rpx) * 60);
        padding-bottom: calc(var(--rpx) * 80);
        border: 2px solid rgb(247, 241, 241);
        left: calc(var(--rpx) * 60);
        bottom: calc(var(--rpx) * 80);
        width: calc(var(--rpx) * 576);
        .card-title {
          font-size: calc(var(--rpx) * 30);
          line-height: 1;
          font-weight: 600;
          color: rgb(31, 47, 38);
          margin-bottom: calc(var(--rpx) * 12);
        }
        .card-desc {
          font-size: calc(var(--rpx) * 20);
          line-height: calc(var(--rpx) * 23);
          color: rgb(31, 47, 38);
        }
      }
    }
    .service-bottom {
      .bottom-title {
        padding-left: calc(var(--rpx) * 60);
        padding-right: calc(var(--rpx) * 60);
        font-weight: 600;
        color: rgb(31, 47, 38);
        font-size: calc(var(--rpx) * 36);
        line-height: 2;
        letter-spacing: -0.02em;
        padding-top: calc(var(--rpx) * 64);
      }
      .bottom-desc-text {
        margin-left: auto;
        margin-right: auto;
        width: calc(var(--rpx) * 701);
        font-size: calc(var(--rpx) * 20);
        line-height: calc(var(--rpx) * 23);
        color: rgb(31, 47, 38);
      }
      .bottom-cards {
        margin-top: calc(var(--rpx) * 119);
        width: calc(var(--rpx) * 769);
        padding-bottom: calc(var(--rpx) * 156);
        margin-left: auto;
        margin-right: auto;
        .col-1 {
          width: calc(var(--rpx) * 268);
        }
        .col-2 {
          width: calc(var(--rpx) * 392);
        }
        .bottom-card {
          padding-top: calc(var(--rpx) * 147);
          &:nth-child(2) {
            margin-top: calc(var(--rpx) * 159);
          }
          .bottom-text {
            font-weight: 500;
            font-size: calc(var(--rpx) * 24);
            line-height: calc(var(--rpx) * 30);
            white-space: nowrap;
            color: rgb(31, 47, 38);
          }
          .card-img-1 {
            width: calc(var(--rpx) * 120);
            height: calc(var(--rpx) * 114.75);
            top: calc(var(--rpx) * 16.6);
          }
          .card-img-2 {
            width: calc(var(--rpx) * 270);
            height: calc(var(--rpx) * 121);
            top: calc(var(--rpx) * 10);
          }
          .card-img-3 {
            width: calc(var(--rpx) * 120);
            height: calc(var(--rpx) * 120);
            top: calc(var(--rpx) * 11.5);
          }
          .card-img-4 {
            width: calc(var(--rpx) * 120);
            height: calc(var(--rpx) * 131.6);
            top: 0;
          }
        }
      }
    }
  }
  .news {
    padding-bottom: calc(var(--rpx) * 147);
    .news-section-title {
      padding-top: calc(var(--rpx) * 206);
      padding-left: calc(var(--rpx) * 60);
      font-weight: 600;
      font-size: calc(var(--rpx) * 36);
      line-height: 2;
      letter-spacing: -0.02em;
      color: rgb(31, 47, 38);
      margin-bottom: calc(var(--rpx) * 12);
    }
    .progress-container {
      margin-left: auto;
      margin-right: auto;
      width: calc(var(--rpx) * 452);
      height: calc(var(--rpx) * 8);
      box-shadow: calc(var(--rpx) * 2) calc(var(--rpx) * 2) calc(var(--rpx) * 6) calc(var(--rpx) * 2) rgba(0, 0, 0, 0.1);
      .progress-bar {
        box-shadow: calc(var(--rpx) * 1) 0px calc(var(--rpx) * 4) 0px rgba(0, 0, 0, 0.25);
        background: rgb(152, 188, 172);
        transform-origin: left;
        transition: transform 0.3s ease;
      }
    }
    .news-swiper {
      padding-bottom: calc(var(--rpx) * 46);
      padding-left: calc(var(--rpx) * 276);
      padding-right: calc(var(--rpx) * 276);
      .swiper-wrapper {
        display: flex;
        .swiper-slide {
          cursor: pointer;
          height: auto;
          width: calc(var(--rpx) * 532);
          margin-right: calc(var(--rpx) * 40);
          box-shadow: calc(var(--rpx) * 4) calc(var(--rpx) * 4) calc(var(--rpx) * 10) calc(var(--rpx) * 3) rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          &:last-child {
            margin-right: 0;
          }
          .news-img {
            width: 100%;
            height: calc(var(--rpx) * 490);
          }
          .news-info {
            flex: 1;
            width: 100%;
            padding: calc(var(--rpx) * 16);
            .news-time {
              font-size: calc(var(--rpx) * 20);
              line-height: calc(var(--rpx) * 24);
              color: rgb(178, 178, 178);
            }
            .news-title {
              font-weight: 500;
              font-size: calc(var(--rpx) * 24);
              line-height: calc(var(--rpx) * 32);
              color: rgb(31, 47, 38);
              margin-top: calc(var(--rpx) * 4);
              margin-bottom: calc(var(--rpx) * 4);
            }
            .news-desc {
              font-size: calc(var(--rpx) * 20);
              line-height: calc(var(--rpx) * 23);
              color: rgb(31, 47, 38);
            }
          }
        }
      }
    }
  }

  .career {
    width: calc(var(--rpx) * 1320);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: calc(var(--rpx) * 50);
    .career-title {
      font-weight: 600;
      font-size: calc(var(--rpx) * 36);
      line-height: 2;
      letter-spacing: -0.02em;
      color: rgb(31, 47, 38);
      margin-bottom: calc(var(--rpx) * 10);
    }
    .job-list {
      width: calc(var(--rpx) * 1120);
      margin-left: auto;
      margin-right: auto;
      .job-grid {
        border-bottom: 1px solid rgba(31, 47, 38, 0.5);
        padding-bottom: calc(var(--rpx) * 40);
        padding-top: calc(var(--rpx) * 40);
        padding-left: calc(var(--rpx) * 92.5);
        padding-right: calc(var(--rpx) * 92.5);
        &:last-child {
          border: none;
        }
        .job-title {
          font-weight: 500;
          font-size: calc(var(--rpx) * 28);
          line-height: calc(var(--rpx) * 32);
          color: rgb(31, 47, 38);
        }
        .job-time {
          font-size: calc(var(--rpx) * 20);
          line-height: calc(var(--rpx) * 24);
          color: rgb(178, 178, 178);
        }
        .job-desc {
          margin-top: calc(var(--rpx) * 21);
          font-size: calc(var(--rpx) * 20);
          line-height: calc(var(--rpx) * 23);
          color: rgb(31, 47, 38);
        }
      }
    }
  }
  .footer {
    padding-top: calc(var(--rpx) * 60);
    padding-bottom: calc(var(--rpx) * 71);
    background: rgb(152, 188, 172);
    .footer-inner {
      width: calc(var(--rpx) * 1320);
      margin-left: auto;
      margin-right: auto;
      .footer-top {
        a {
          padding: calc(var(--rpx) * 4) calc(var(--rpx) * 4) calc(var(--rpx) * 4) calc(var(--rpx) * 16);
          background: rgb(31, 47, 38);
          color: rgb(247, 241, 241);
          font-weight: 600;
          font-size: calc(var(--rpx) * 16);
          line-height: calc(var(--rpx) * 20);
          .arrow-wrapper {
            width: calc(var(--rpx) * 40);
            height: calc(var(--rpx) * 40);
            margin-left: calc(var(--rpx) * 12);
          }
        }
      }
      .footer-center {
        border-top: 1px solid rgb(31, 47, 38);
        border-bottom: 1px solid rgb(31, 47, 38);
        padding-bottom: calc(var(--rpx) * 12);
        padding-top: calc(var(--rpx) * 35);
        margin-top: calc(var(--rpx) * 25);
        .footer-social {
          margin-top: calc(var(--rpx) * 72);
        }
        .instagram {
          width: calc(var(--rpx) * 24);
          height: calc(var(--rpx) * 24);
        }
        .linkedin {
          width: calc(var(--rpx) * 24);
          height: calc(var(--rpx) * 24);
          margin-left: calc(var(--rpx) * 32);
        }
        .col-2 {
          width: calc(var(--rpx) * 226);
        }
        .col-3 {
          margin-left: calc(var(--rpx) * 90);
          margin-right: calc(var(--rpx) * 76);
        }
        .col-title {
          white-space: nowrap;
          font-weight: 500;
          font-size: calc(var(--rpx) * 18);
          line-height: 2;
          color: rgb(31, 47, 38);
        }
        .col-item {
          white-space: nowrap;
          font-size: calc(var(--rpx) * 18);
          line-height: 2;
          color: rgb(31, 47, 38);
        }
      }
      .footer-bottom {
        padding-top: calc(var(--rpx) * 12);
        .bottom-left {
          color: rgba(31, 47, 38, 0.8);
          font-size: calc(var(--rpx) * 16);
          line-height: calc(var(--rpx) * 22);
          .copyright {
            font-size: calc(var(--rpx) * 20);
            line-height: calc(var(--rpx) * 22);
            margin-right: calc(var(--rpx) * 8);
          }
          .separator {
            margin-left: calc(var(--rpx) * 8);
            margin-right: calc(var(--rpx) * 8);
          }
        }
        .bottom-right {
          color: rgba(31, 47, 38, 0.8);
          font-size: calc(var(--rpx) * 16);
          line-height: calc(var(--rpx) * 22);
          .separator {
            margin-left: calc(var(--rpx) * 8);
            margin-right: calc(var(--rpx) * 8);
          }
        }
      }
    }
  }
  #privacy-popup, #terms-popup {
    .popup-scroll-bottom {
      height: calc(100% - calc(var(--rpx) * 84));
    }
    .popup-scroll-wrapper {
      width: calc(var(--rpx) * 1054);
      padding-left: calc(var(--rpx) * 60);
      padding-right: calc(var(--rpx) * 60);
      margin-left: auto;
      margin-right: auto;
      overflow-y: scroll;
      .popup-scroll-text {
        color: rgb(31, 47, 38);
        font-size: calc(var(--rpx) * 20);
        line-height: 1.15;
      }
    }
  }
  .popup {
    display: none;
    position: fixed;
    padding-bottom: calc(var(--rpx) * 60);
    height: calc(100vh - calc(var(--rpx) * 129));
    top: calc(var(--rpx) * 129);
    left: 0;
    width: calc(var(--vw) * 100);
    background: rgba(247, 241, 241, 0.9);
    backdrop-filter: blur(calc(var(--rpx) * 5));
    z-index: 45;
    .popup-top {
      padding-left: calc(var(--rpx) * 60);
      padding-right: calc(var(--rpx) * 60);
      height: calc(var(--rpx) * 72);
      margin-bottom: calc(var(--rpx) * 12);
      .popup-title {
        font-size: calc(var(--rpx) * 36);
        color: rgb(31, 47, 38);
      }
      .popup-close {
        width: calc(var(--rpx) * 44);
        height: calc(var(--rpx) * 44);
        cursor: pointer;
      }
    }
    .popup-bottom {
      padding-left: calc(var(--rpx) * 138);
      padding-right: calc(var(--rpx) * 138);
      margin-top: calc(var(--rpx) * 48);
      .prev-button {
        font-size: calc(var(--rpx) * 20);
        line-height: 1;
        cursor: pointer;
        color: rgb(31, 47 ,38);
        &.swiper-button-disabled {
          color: rgba(31, 47 ,38, 0.5);
        }
      }
      .next-button {
        font-size: calc(var(--rpx) * 20);
        line-height: 1;
        cursor: pointer;
        color: rgb(31, 47 ,38);
        &.swiper-button-disabled {
          color: rgba(31, 47 ,38, 0.5);
        }
      }
      .popup-progress {
        opacity: 0;
        width: calc(var(--rpx) * 452);
        height: calc(var(--rpx) * 8);
        overflow: hidden;
        position: relative;
        box-shadow: calc(var(--rpx) * 2) calc(var(--rpx) * 2) calc(var(--rpx) * 6) calc(var(--rpx) * 2) rgba(0, 0, 0, 0.1);
        .popup-progress-bar {
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background: rgba(152, 188, 172, 0.8);
          box-shadow: calc(var(--rpx) * 1) 0px calc(var(--rpx) * 4) 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
    .popup-swiper {
      height: 100%;
      .swiper-wrapper {
        display: flex;
        .swiper-slide {
          height: 100%;
          width: calc(var(--vw) * 100);
          .news-detail {
            width: calc(var(--rpx) * 910);
            margin-left: auto;
            margin-right: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .news-top {
              width: 100%;
              flex: 1;
              position: relative;
              margin-bottom: calc(var(--rpx) * 40);
            }
            .news-text {
              height: calc(var(--rpx) * 161);
              width: 100%;
              position: relative;
              .news-text-progress {
                width: calc(var(--rpx) * 8);
                height: 96%;
                position: absolute;
                right: 0;
                top: 0;
                overflow: hidden;
                box-shadow: calc(var(--rpx) * 2) calc(var(--rpx) * 2) calc(var(--rpx) * 6) calc(var(--rpx) * 2) rgba(0, 0, 0, 0.1);
                .news-text-progress-bar {
                  box-shadow: calc(var(--rpx) * 1) 0px calc(var(--rpx) * 4) 0px rgba(0, 0, 0, 0.25);
                  background: rgb(152, 188, 172);
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                }
              }
              .news-text-inner {
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
              .news-text-wrapper {
                padding-bottom: calc(var(--rpx) * 48);
                width: calc(var(--rpx) * 571);
                margin-left: auto;
                margin-right: auto;
                .news-title {
                  font-size: calc(var(--rpx) * 24);
                  line-height: calc(var(--rpx) * 32);
                  color: rgb(31, 47, 38);
                }
                .news-time {
                  white-space: nowrap;
                  margin-left: calc(var(--rpx) * 40);
                  font-size: calc(var(--rpx) * 20);
                  line-height: calc(var(--rpx) * 24);
                  color: rgb(178, 178, 178);
                }
                .news-content {
                  margin-top: calc(var(--rpx) * 4);
                  font-size: calc(var(--rpx) * 20);
                  line-height: calc(var(--rpx) * 23);
                  color: rgb(31, 47, 38);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 649px) {
  #app {
    padding-top: calc(var(--rpx) * 72);
    overflow-x: hidden;
    .anchor {
      top: calc(var(--rpx) * (-72));
    }
    #nav {
      .nav-top {
        display: none;
      }
      .nav-content {
        height: calc(var(--rpx) * 72);
        padding-left: calc(var(--rpx) * 0);
        padding-right: calc(var(--rpx) * 0);
        .nav-inner {
          padding-left: calc(var(--rpx) * 30);
          padding-right: calc(var(--rpx) * 30);
          box-shadow: 0px calc(var(--rpx) * 4) calc(var(--rpx) * 4) 0px rgba(0, 0, 0, 0.1);
          height: calc(var(--rpx) * 72);
          padding-top: calc(var(--rpx) * 32.5);
          padding-bottom: calc(var(--rpx) * 22.5);
          .nav-logo {
            width: calc(var(--rpx) * 48);
            height: calc(var(--rpx) * 48);
            position: relative;
            top: calc(var(--rpx) * (-2));
          }
          #hamburger {
            width: calc(var(--rpx) * 30);
            height: calc(var(--rpx) * 25);
          }
        }
      }
    }

    .about {
      .about-top {
        height: auto;
        .about-text {
          position: relative;
          right: 0;
          bottom: 0;
          width: calc(var(--vw) * 100);
          padding-left: calc(var(--rpx) * 31);
          padding-right: calc(var(--rpx) * 31);
          border: none;
          padding-top: calc(var(--rpx) * 42);
          padding-bottom: calc(var(--rpx) * 52);
          .text-1 {
            padding: calc(var(--rpx) * 30);
            border: 2px solid rgb(247, 241, 241);
            box-shadow: calc(var(--rpx) * 3) calc(var(--rpx) * 3) calc(var(--rpx) * 8) 0px rgba(0, 0, 0, 0.25);

          }
          .text-2 {
            margin-top: calc(var(--rpx) * 22);
            padding: calc(var(--rpx) * 30);
            border: 2px solid rgb(247, 241, 241);
            box-shadow: calc(var(--rpx) * 3) calc(var(--rpx) * 3) calc(var(--rpx) * 8) 0px rgba(0, 0, 0, 0.25);
          }
          .about-title {
            font-size: calc(var(--rpx) * 20);
            margin-bottom: calc(var(--rpx) * 5);
          }
          .about-desc {
            font-size: calc(var(--rpx) * 15);
            line-height: calc(var(--rpx) * 18);
          }
        }
        .mo-bg {
          width: 100%;
          height: calc(var(--rpx) * 365);
        }
      }
      .about-bottom {
        flex-direction: column;
        padding-top: calc(var(--rpx) * 50);
        padding-bottom: calc(var(--rpx) * 50);
        align-items: center;
        .about-card {
          width: calc(var(--rpx) * 248);
          height: calc(var(--rpx) * 210);
          box-shadow: calc(var(--rpx) * 2.34) calc(var(--rpx) * 2.34) calc(var(--rpx) * 5.85) calc(var(--rpx) * 1.75) rgba(31, 47, 38, 0.1);
          margin-bottom: calc(var(--rpx) * 8);
          padding-bottom: calc(var(--rpx) * 24);
          .card-title {
            font-size: calc(var(--rpx) * 18);
            margin-bottom: calc(var(--rpx) * 8);
          }
          .card-desc {
            font-size: calc(var(--rpx) * 9.36);
            line-height: calc(var(--rpx) * 11.7);
          }
          .about-img-1 {
            width: calc(var(--rpx) * 100.6);
            height: calc(var(--rpx) * 74);
            top: calc(var(--rpx) * 45.5);
          }
          .about-img-2 {
            width: calc(var(--rpx) * 88.3);
            height: calc(var(--rpx) * 71);
            top: calc(var(--rpx) * 40.36);
          }
          .about-img-3 {
            width: calc(var(--rpx) * 108.3);
            height: calc(var(--rpx) * 108.3);
            top: calc(var(--rpx) * 18.7);
          }
        }
      }
    }

    .service {
      .service-top {
        padding-top: calc(var(--rpx) * 50);
        padding-bottom: calc(var(--rpx) * 50);
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .service-bg {
          position: relative;
          height: calc(var(--rpx) * 254);
          width: calc(var(--rpx) * 372);
          margin-bottom: calc(var(--rpx) * 30);
        }
        .service-card {
          position: relative;
          padding: calc(var(--rpx) * 30);
          width: calc(var(--rpx) * 372);
          left: 0;
          bottom: 0;
          box-shadow: calc(var(--rpx) * 3) calc(var(--rpx) * 3) calc(var(--rpx) * 8) 0px rgba(0, 0, 0, 0.25);
          .card-title {
            font-size: calc(var(--rpx) * 20);
            margin-bottom: calc(var(--rpx) * 4);
          }
          .card-desc {
            font-size: calc(var(--rpx) * 15);
            line-height: 1.2;
          }
        }
      }
      .service-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bottom-title {
          font-size: calc(var(--rpx) * 20);
          line-height: 1.2;
          padding-top: calc(var(--rpx) * 50);
          padding-left: 0;
          padding-right: 0;
          letter-spacing: 0;
          margin-bottom: calc(var(--rpx) * 40);
        }
        .bottom-desc-text {
          width: calc(var(--rpx) * 312);
          font-size: calc(var(--rpx) * 15);
          line-height: calc(var(--rpx) * 18);;
        }
        .bottom-cards {
          flex-direction: column;
          align-items: center;
          margin-top: 0;
          width: calc(var(--rpx) * 325);
          padding-bottom: calc(var(--rpx) * 0);
          .col-1 {
            width: 100%;
          }
          .col-2 {
            width: 100%;
          }
          .bottom-card {
            margin-bottom: calc(var(--rpx) * 40);
            &:nth-child(1) {
              padding-top: calc(var(--rpx) * 86.5);
            }
            &:nth-child(2) {
              padding-top: calc(var(--rpx) * 91);
              margin-top: 0;
            }
            &:nth-child(3) {
              padding-top: calc(var(--rpx) * 90);
            }
            &:nth-child(4) {
              padding-top: calc(var(--rpx) * 98);
            }
            .bottom-text {
              font-size: calc(var(--rpx) * 16);
              line-height: calc(var(--rpx) * 20);
            }
            .card-img-1 {
              width: calc(var(--rpx) * 80);
              height: calc(var(--rpx) * 76.5);
              top: 0;
            }
            .card-img-2 {
              width: calc(var(--rpx) * 180);
              height: calc(var(--rpx) * 80.8);
              top: 0;
            }
            .card-img-3 {
              width: calc(var(--rpx) * 80);
              height: calc(var(--rpx) * 80);
              top: 0;
            }
            .card-img-4 {
              width: calc(var(--rpx) * 80);
              height: calc(var(--rpx) * 87.7);
              top: 0;
            }
          }
        }
      }
    }

    .news {
      padding-top: calc(var(--rpx) * 60);
      padding-bottom: calc(var(--rpx) * 40);
      display: flex;
      flex-direction: column;
      align-items: center;
      .news-section-title {
        font-size: calc(var(--rpx) * 20);
        line-height: 1.2;
        margin-bottom: calc(var(--rpx) * 40);
        padding-left: 0;
        padding-top: 0;
      }
      #mo-expand {
        cursor: pointer;
        padding-top: calc(var(--rpx) * 20);
        color: rgba(31, 47, 38, 0.8);
        font-size: calc(var(--rpx) * 16);
        line-height: calc(var(--rpx) * 20);
      }
      .news-swiper {
        padding-left: calc(var(--rpx) * 30);
        padding-right: calc(var(--rpx) * 30);
        width: 100%;
        padding-bottom: calc(var(--rpx) * 0);
        .swiper-wrapper {
          flex-direction: column;
          .swiper-slide {
            width: 100%;
            margin-right: 0;
            margin-bottom: calc(var(--rpx) * 20);
            .news-img {
              height: calc(var(--rpx) * 267);
            }
            .news-info {
              .news-info-top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: calc(var(--rpx) * 4);
              }
              .news-time {
                order: 2;
                font-size: calc(var(--rpx) * 16);
                line-height: calc(var(--rpx) * 24);
                white-space: nowrap;
                margin-left: calc(var(--rpx) * 40);
              }
              .news-title {
                order: 1;
                flex: 1;
                font-size: calc(var(--rpx) * 18);
                line-height: calc(var(--rpx) * 24);
                margin-top: 0;
                margin-bottom: 0;
              }
              .news-desc {
                font-size: calc(var(--rpx) * 16);
                line-height: calc(var(--rpx) * 23);
              }
            }
          }
        }
      }
    }

    .footer {
      padding-top: calc(var(--rpx) * 24);
      padding-bottom: calc(var(--rpx) * 32);
      .footer-inner {
        width: calc(var(--rpx) * 370);
        .footer-top {
          a {
            padding: calc(var(--rpx) * 4) calc(var(--rpx) * 7);
            font-size: calc(var(--rpx) * 14);
            line-height: calc(var(--rpx) * 20);
            letter-spacing: 0.01em;
            .arrow-wrapper {
              width: calc(var(--rpx) * 29);
              height: calc(var(--rpx) * 29);
              margin-left: calc(var(--rpx) * 4);
            }
          }
        }
        .footer-mo-center {
          margin-bottom: calc(var(--rpx) * 14);
          font-size: calc(var(--rpx) * 18);
          line-height: 2;
          color: rgb(31, 47, 38);
          a {
            color: rgb(31, 47, 38);
          }
          .footer-social {
            margin-top: 0;
            .instagram {
              width: calc(var(--rpx) * 18);
              height: calc(var(--rpx) * 18);
            }
            .linkedin {
              width: calc(var(--rpx) * 18);
              height: calc(var(--rpx) * 18);
              margin-left: calc(var(--rpx) * 32);
            }
          }
          .footer-arrow-down {
            width: calc(var(--rpx) * 12);
            height: calc(var(--rpx) * 7);
            margin-left: calc(var(--rpx) * 3);
            transform: rotate(180deg);
            &.active {
              transform: rotate(0deg);
            }
          }
          .footer-location-wrapper {
            width: calc(var(--vw) * 100);
            overflow: hidden;
            height: 0;
            font-size: calc(var(--rpx) * 14);
            line-height: calc(var(--rpx) * 24);
            color: rgb(31, 47, 38);
            &.active {
              height: auto;
            }
            .line-top {
              height: 1px;
              box-shadow: 0px 0px calc(var(--rpx) * 4) calc(var(--rpx) * 2) rgba(0, 0, 0, 0.1);
              margin-bottom: calc(var(--rpx) * 42);
            }
            .line-bottom {
              height: 1px;
              box-shadow: 0px 0px calc(var(--rpx) * 4) calc(var(--rpx) * 1) rgba(0, 0, 0, 0.1);
              margin-top: calc(var(--rpx) * 45);
            }
          }
        }
        .footer-bottom {
          padding-top: 0;
          .bottom-left {
            font-size: calc(var(--rpx) * 10);
            line-height: calc(var(--rpx) * 18);
            .copyright {
              font-size: calc(var(--rpx) * 10);
              line-height: calc(var(--rpx) * 18);
            }
          }
          .bottom-right {
            font-size: calc(var(--rpx) * 10);
            line-height: calc(var(--rpx) * 22);
          }
        }
      }
    }

    .mo-nav {
      transform: translateX(100%);
      transition: transform 0.3s ease;
      position: fixed;
      top: 0;
      left: 0;
      width: calc(var(--vw) * 100);
      height: 100vh;
      z-index: 52;
      .shadow-cover {
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(247, 241, 241, 0.8);
        height: calc(100vh - calc(var(--rpx) * 72));
      }
      .nav-panel {
        background: rgb(31, 47, 38);
        box-shadow: 0px calc(var(--rpx) * 4) calc(var(--rpx) * 8) 0px rgba(0, 0, 0, 0.1);
        top: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        width: calc(var(--rpx) * 265);
        padding-right: calc(var(--rpx) * 30);
        padding-top: calc(var(--rpx) * 40);
        padding-bottom: calc(var(--rpx) * 40);
        .panel-top {
          #hamburger-close {
            width: calc(var(--rpx) * 30);
            height: calc(var(--rpx) * 22);
            margin-bottom: calc(var(--rpx) * 54);
          }
          .nav-link {
            color: rgb(247, 241, 241);
            font-size: calc(var(--rpx) * 18);
            line-height: 2;
          }
        }
        .panel-bottom {
          .contact-button {
            background: rgb(247, 241, 241);
            padding: calc(var(--rpx) * 4) calc(var(--rpx) * 3);
            font-size: calc(var(--rpx) * 14);
            line-height: calc(var(--rpx) * 20);
            margin-bottom: calc(var(--rpx) * 36);
            .arrow-wrapper {
              width: calc(var(--rpx) * 29);
              height: calc(var(--rpx) * 29);
              margin-left: calc(var(--rpx) * 4);
            }
          }
          .footer-social {
            .instagram {
              width: calc(var(--rpx) * 18);
              height: calc(var(--rpx) * 18);
            }
            .linkedin {
              width: calc(var(--rpx) * 18);
              height: calc(var(--rpx) * 18);
              margin-left: calc(var(--rpx) * 32);
            }
          }
        }
      }
    }

    .home {
      .home-bg {
        object-position: 28% 50%;
      }
      .home-top {
        width: calc(var(--rpx) * 370);
        padding-top: calc(var(--rpx) * 20);
        padding-bottom: calc(var(--rpx) * 9);
        .home-logo {
          width: calc(var(--rpx) * 100);
          height: calc(var(--rpx) * 50);
        }
      }
      .home-text-1 {
        font-size: calc(var(--rpx) * 20);
        letter-spacing: -0.05em;
        left: calc(var(--rpx) * 163);
        bottom: 15.6vh;
      }
      .home-text-2 {
        font-size: calc(var(--rpx) * 26.4);
        line-height: calc(var(--rpx) * 33.4);
        letter-spacing: -0.05em;
        left: calc(var(--rpx) * 30);
        bottom: 7.9vh;
      }
    }
    .career {
      width: calc(var(--rpx) * 370);
      padding-bottom: calc(var(--rpx) * 80);
      .career-title {
        font-size: calc(var(--rpx) * 20);
        letter-spacing: 0;
        margin-bottom: 0;
      }
      .job-list {
        width: calc(var(--rpx) * 354);
        .job-grid {
          padding-left: 0;
          padding-right: 0;
          padding-top: calc(var(--rpx) * 30);
          padding-bottom: calc(var(--rpx) * 30);
          .job-title {
            font-size: calc(var(--rpx) * 18);
            line-height: calc(var(--rpx) * 32);
          }
          .job-time {
            font-size: calc(var(--rpx) * 16);
            line-height: calc(var(--rpx) * 24);
          }
          .job-desc {
            font-size: calc(var(--rpx) * 16);
            line-height: calc(var(--rpx) * 18);
            margin-top: calc(var(--rpx) * 4);
          }
        }
      }
    }

    .terms {
      padding-top: calc(var(--rpx) * 14);
      padding-bottom: calc(var(--rpx) * 70);
      .terms-title {
        padding-left: calc(var(--rpx) * 30);
        padding-right: calc(var(--rpx) * 30);
        font-size: calc(var(--rpx) * 20);
        line-height: 1;
        color: rgb(31, 47, 38);
        margin-bottom: calc(var(--rpx) * 28);
      }
      .terms-content {
        width: calc(var(--rpx) * 338);
        margin-left: auto;
        margin-right: auto;
        color: rgb(31, 47, 38);
        font-size: calc(var(--rpx) * 16);
        line-height: calc(var(--rpx) * 18);
      }
    }

    .privacy {
      padding-top: calc(var(--rpx) * 14);
      padding-bottom: calc(var(--rpx) * 70);
      .privacy-title {
        padding-left: calc(var(--rpx) * 30);
        padding-right: calc(var(--rpx) * 30);
        font-size: calc(var(--rpx) * 20);
        line-height: 1;
        color: rgb(31, 47, 38);
        margin-bottom: calc(var(--rpx) * 28);
      }
      .privacy-content {
        width: calc(var(--rpx) * 338);
        margin-left: auto;
        margin-right: auto;
        color: rgb(31, 47, 38);
        font-size: calc(var(--rpx) * 16);
        line-height: calc(var(--rpx) * 18);
      }
    }

    .mo-news {
      width: calc(var(--rpx) * 370);
      margin-left: auto;
      margin-right: auto;
      padding-top: calc(var(--rpx) * 14);
      padding-bottom: calc(var(--rpx) * 32);
      .mo-news-title {
        font-size: calc(var(--rpx) * 20);
        line-height: 1;
        color: rgb(31, 47, 38);
        margin-bottom: calc(var(--rpx) * 26);
      }
      .news-card {
        box-shadow: calc(var(--rpx) * 4) calc(var(--rpx) * 4) calc(var(--rpx) * 10) calc(var(--rpx) * 3) rgba(0, 0, 0, 0.1);
        margin-bottom: calc(var(--rpx) * 20);
        .news-img {
          height: calc(var(--rpx) * 267);
        }
        .news-info {
          padding: calc(var(--rpx) * 16);
          .news-time {
            color: rgb(178, 178, 178);
            font-size: calc(var(--rpx) * 16);
            line-height: calc(var(--rpx) * 24);
            white-space: nowrap;
            margin-left: calc(var(--rpx) * 32);
          }
          .news-title {
            font-size: calc(var(--rpx) * 18);
            line-height: calc(var(--rpx) * 32);
            color: rgb(31, 47, 38);
            flex: 1;
          }
          .news-content {
            color: rgb(31, 47, 38);
            font-size: calc(var(--rpx) * 16);
            line-height: calc(var(--rpx) * 23);
            margin-top: calc(var(--rpx) * 4);
          }
        }
      }
    }
  }
}